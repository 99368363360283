import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { Columns } from "react-bulma-components"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/seo"


const Post = ({ pageContext, location }) => {
  let time;
  if (pageContext.RecurringDate || (pageContext.StartsAt && !pageContext.HideDate)) {
    const startDate = new Date(pageContext.StartsAt)
    const displayDate = startDate.toLocaleTimeString('de-DE', { timeZone: 'Europe/Berlin', weekday: 'short', day: 'numeric', month: 'long', year: 'numeric', hour: "2-digit", minute: "2-digit" })
    time = pageContext.RecurringDate || displayDate + " Uhr"
  } else {
    time = null;
  }

  return (
    <Layout location={location}>
      <Seo title={pageContext.Title} description={time} imageData={pageContext.MainImage?.localFile.childImageSharp.gatsbyImageData} />

      <Columns className="mt-5 is-centered">
        <Columns.Column mobile={{ size: 12 }} tablet={{ size: 10 }}>
          <h3 className="has-text-centered has-text-weight-bold angled">{ time }</h3>
          <h1 className="title is-3 has-text-centered angled"><mark>{ pageContext.Title }</mark></h1>
          <div className="pt-6 page-content" dangerouslySetInnerHTML={{ __html:  pageContext.Content.data.Content.replaceAll("/uploads/", "https://cms.wilma19.de/uploads/")}}></div>
        </Columns.Column>
      </Columns>

      <Columns className="mt-3 is-centered">
        { pageContext.Pictures?.map(p => (
          <Columns.Column mobile={{ size: 12 }} tablet={{ size: 6 }}>
            <GatsbyImage
              className="preview-image"
              image={p.localFile.childImageSharp.gatsbyImageData}
              width='100%'
            />
          </Columns.Column>
        ))}
      </Columns>

      <Columns className="mt-3 is-centered">
        <Columns.Column mobile={{ size: 12 }} tablet={{ size: 10 }}>
          <Link className="level-right colored is-size-5 has-text-weight-bold" to="/">← zur Startseite</Link>
        </Columns.Column>
      </Columns>
    </Layout>
  )
}

export default Post
